<template>
    <div>
        <el-date-picker size="small" clearable type="daterange" v-model="dateRange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            Rangeseparator = "to"
            Startplaceholder = "start date"
            Endplaceholder = "end date" > </el-date-picker>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                pickerOptions: {
                    disabledDate (time) {
                        return time.getTime() > Date.now()
                    }
                },
                dateRange: []
            }
        }
    }
</script>